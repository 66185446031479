function checkDistributor() {
  var prod_id = $( "#subscription_paddle_product_id" ).val();
  if ($( "#subscription_contact_id option:selected" ).attr("data-use_invoicing") === "false") {
    $( "#subscription-form" ).attr('action', "/finalize?product=" + prod_id);
    $( "#continue" ).text("Register");
  } else {
    $( "#subscription-form" ).attr('action', "/choose_payment?product=" + prod_id);
    $( "#continue" ).text("Continue to payment");
  }

  if ($( "#subscription_contact_id option:selected" ).attr("data-use_code") === "true") {
    $( ".security-code" ).show();
  } else {
    $( "#code" ).val('');
    $( ".security-code" ).hide();
  }

  if ($( "#subscription_contact_id option:selected" ).attr("data-free_period") === "true") {
    $( ".serial-number" ).show();
  } else {
    $( "#serial_number" ).val('');
    $( ".serial-number" ).hide();
  }
}

function togglePass() {
  const password = document.querySelector('#password');
  const eye = document.querySelector('#eye');
  const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
  password.setAttribute('type', type);

  eye.classList.toggle("fa-eye");
  eye.classList.toggle("fa-eye-slash");
}

function togglePassConfirm() {
  const password = document.querySelector('#password_confirmation');
  const eye = document.querySelector('#eye-confirm');
  const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
  password.setAttribute('type', type);

  eye.classList.toggle("fa-eye");
  eye.classList.toggle("fa-eye-slash");
}

$( "#subscription_contact_id" ).change(checkDistributor);
$( "#product" ).change(function(){
  prod_id = $( "#product" ).val();
  if (prod_id) {
    window.location = window.location.href.replace( /[\?#].*|$/, "?product="+ prod_id )
  }
});

$("#subscription_vat_number").change(function() {
  console.log("EMAIL", $( "#subscription_invoicing_email" ).val().length)
  console.log("EMAIL", $( "#subscription_invoicing_city" ).val().length)
  $.ajax({
    type: "POST",
    url: '/vat_number_check',
    data: {
      user_email: function() {
        if ($( "#subscription_invoicing_email" ).val().length > 0)  {
          return $( "#subscription_invoicing_email" ).val();
        } else {
          return $( "#subscription_user_email" ).val();
        }
      },
      company_name: function() {
        return $( "#subscription_company_name" ).val();
      },
      address: function() {
        if ($( "#subscription_invoicing_address" ).val().length > 0)  {
          return $( "#subscription_invoicing_address" ).val();
        } else {
          return $( "#subscription_address" ).val();
        }
      },
      city: function() {
        if ($( "#subscription_invoicing_city" ).val().length > 0)  {
          return $( "#subscription_invoicing_city" ).val();
        } else {
          return $( "#subscription_city" ).val();
        }
      },
      postcode: function() {
        if ($( "#subscription_invoicing_postcode" ).val().length > 0)  {
          return $( "#subscription_invoicing_postcode" ).val();
        } else {
          return $( "#subscription_postcode" ).val();
        }
      },
      invoicing_country: function() {
        if ($( "#subscription_invoicing_country_2" ).val().length > 0)  {
          return $( "#subscription_invoicing_country_2" ).val();
        } else {
          return $( "#subscription_invoicing_country" ).val();
        }
      },
      vat_number: function() {
        return $( "#subscription_vat_number" ).val();
      }
    },
    dataType: 'json'
  }).done( function( data ) {
    console.log("data", data)
    if (data.success === true) {
      $( "#subscription_paddle_user_id" ).val(data.message);
      $("#vat_number_error").text("").removeClass("error");
      $("#subscription_vat_number").removeClass("not-valid");
    } else {
      $("#vat_number_error").text(data.message).addClass("error");
      $("#subscription_vat_number").removeClass("valid");
      $("#subscription_vat_number").addClass("not-valid");
    }
  })
});

document.querySelector('#eye').addEventListener('click', function (e) {
  togglePass();
});

document.querySelector('#eye-confirm').addEventListener('click', function (e) {
  togglePassConfirm();
});


